import React, { useState } from 'react';

import ActivitySplashAction from 'presential/activity-splash-actions/ActivitySplashAction';
import ActivityMirror from 'presential/activity-mirror/ActivityMirror';
import sceneInteraction from 'services/scene-interaction';
import './ScreenMirror.scss';

/**
 * The main screen to display for the mirror activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenMirror = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState<boolean>(true);
  const [isInsideMirrorActivity, setIsInsideMirrorActivity] = useState<boolean>(false);
  const [isContinueDisabled, setToggleContinue] = useState<boolean>(false);

  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen && !isInsideMirrorActivity) {
      sceneInteraction.goToBarScreen(true);
    } else if (isInsideMirrorActivity) {
      setIsInsideMirrorActivity(false);
    } else {
      setHasSplashScreen(false);
      setIsInsideMirrorActivity(true);
      setToggleContinue(true);
      window?.setTimeout(() => {
        setToggleContinue(false);
      }, 16000);
    }
  };

  return (
    <div className="screen-mirror">
      <ActivityMirror isAfterMirror={!isInsideMirrorActivity} />
      <ActivitySplashAction isContinueDisabled onContinueButton={() => !isContinueDisabled && goToNextStep()} />
    </div>
  );
};
export default ScreenMirror;
