import { ECocktaiIngredientlList } from 'enum/cocktail';

export const SELECT_INGREDIENT = 'SELECT_INGREDIENT';

export function selectIngredient(ingredient: ECocktaiIngredientlList | null, index: number) {
  return {
    type: SELECT_INGREDIENT,
    selectedIngredient: ingredient,
    index,
  };
}
