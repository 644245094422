export enum ECocktaiIngredientlList {
  COULIS_CERAMIQUE = 'CoulisCeramique',
  FORMALEHYDE = 'Formalehyde',
  INGREDIENT_BATTERIE = 'IngredientBatterie',
  INGREDIENT_TOLUENE = 'IngredientToluene',
  INGREDIENT_BOULESMITES = 'IngredientBoulesMites',
  INGREDIENT_DETERGENT_TOILETTE = 'IngredientDetergentToilette',
  INGREDIENT_DISSOLVANT_ONGLES = 'IngredientDissolvantOngles',
  INGREDIENT_GOUDRON = 'IngredientGoudron',
  INGREDIENT_LAVE_GLACE = 'IngredientLaveGlace',
  INGREDIENT_MERCURE = 'IngredientMercure',
  INGREDIENT_PESTICIDE = 'IngredientPesticide',
  NICKEL = 'Nickel',
  PLOMB = 'Plomb',
}

export enum ECocktailDND {
  INGREDIENT = 'INGREDIENT',
}
