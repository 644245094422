export const SET_STEP_NUMBER_TOYS = 'SET_STEP_NUMBER_TOYS';
export const SET_FINAL_STEP_NUMBER_TOYS = 'SET_FINAL_STEP_NUMBER_TOYS';
export const SET_IS_DROPPED = 'SET_IS_DROPPED';
export const SET_IS_CRANE_DOWN = 'SET_IS_CRANE_DOWN';

export function setStepNumberToys(stepNumberToys: number) {
  return {
    type: SET_STEP_NUMBER_TOYS,
    stepNumberToys,
  };
}

export function setFinalStepNumberToys(finalStepNumberToys: number) {
  return {
    type: SET_FINAL_STEP_NUMBER_TOYS,
    finalStepNumberToys,
  };
}

export function setCraneIsDown(isCraneDown: boolean) {
  return {
    type: SET_IS_CRANE_DOWN,
    isCraneDown,
  };
}

export function setDropState(isDropped: boolean) {
  return {
    type: SET_IS_DROPPED,
    isDropped,
  };
}
