import { setStepNumberToys, setFinalStepNumberToys, setCraneIsDown, setDropState } from 'action/toys-action';
import { IReduxState } from 'interface/i-redux-state';
import store from 'store/Store';
import { IToysData } from 'interface/i-toys';

class ToysInteraction {
  constructor() {
    this.setStepNumberToys = this.setStepNumberToys.bind(this);
    this.setFinalStepNumberToys = this.setFinalStepNumberToys.bind(this);
  }

  setStepNumberToys(stepNumberToys: number) {
    store.dispatch(setStepNumberToys(stepNumberToys));
  }

  setFinalStepNumberToys(finalStepNumberToys: number) {
    store.dispatch(setFinalStepNumberToys(finalStepNumberToys));
  }

  setCraneIsDown(isCraneDown: boolean) {
    store.dispatch(setCraneIsDown(isCraneDown));
  }

  setDropState(isDropped: boolean) {
    store.dispatch(setDropState(isDropped));
  }

  getToysInfo(): IToysData {
    const state: IReduxState = store.getState();
    return {
      stepNumberToys: state.stepNumberToys,
      finalStepNumberToys: state.finalStepNumberToys,
      isDropped: state.isDropped,
      isCraneDown: state.isCraneDown,
    };
  }
}

export default new ToysInteraction();
