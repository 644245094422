import React from 'react';

import { useSelector } from 'react-redux';
import H5AudioPlayer from 'react-h5-audio-player';

import { EMusicScene } from 'enum/scene';
import { IReduxState } from 'interface/i-redux-state';
import { BruitExterieurMP3, LoungeFarMP3, LoungeMP3 } from 'services/exports-list';
import './MusicLoader.scss';

/**
 * Component that will load the good music base on the Redux Store `currentMusic` params.
 * @returns React.ReactElement
 */

export const MusicLoader = (): React.ReactElement => {
  const state: IReduxState = useSelector((newState: IReduxState) => newState);
  const soundToLoad = (): React.ReactElement => {
    if (!state.isMusicPlaying) {
      return <></>;
    }
    switch (state.currentMusic) {
      case EMusicScene.OUTSIDE:
        return (
          <>
            <H5AudioPlayer src={LoungeFarMP3} loop autoPlay />
            <H5AudioPlayer src={BruitExterieurMP3} loop autoPlay />
          </>
        );
      case EMusicScene.INSIDE:
        return <H5AudioPlayer src={LoungeMP3} loop autoPlay />;
      case EMusicScene.ACTIVITY:
        return <H5AudioPlayer src={LoungeFarMP3} loop autoPlay />;
      case EMusicScene.QUIZZ:
        return <></>;
      default:
        return <></>;
    }
  };

  return <div className="music-loader">{soundToLoad()}</div>;
};

export default MusicLoader;
