import React, { MouseEventHandler } from 'react';
import Button from 'component/button/Button';
import './ActivitySplashAction.scss';

interface IProps {
  isBackDisabled?: boolean;
  backButtonLabel?: string;
  isContinueDisabled?: boolean;
  continueButtonLabel?: string;
  onContinueButton?: MouseEventHandler<HTMLButtonElement>;
}
const ActivitySplashAction = ({
  isBackDisabled = false,
  backButtonLabel = 'Recommencer',
  continueButtonLabel = 'Continuer',
  isContinueDisabled = false,
  onContinueButton = (): void => {},
}: IProps): React.ReactElement => {
  return (
    <div className="activity-splash-action">
      <div className="activity-splash-action__container">
        <Button
          isStyled
          className="activity-splash-action__back-button"
          isDisabled={isBackDisabled}
          onClick={() => !isBackDisabled && window.location.reload()}
        >
          {backButtonLabel}
        </Button>
        {!isContinueDisabled && (
          <Button
            isStyled
            className="activity-splash-action__next-button"
            isDisabled={isContinueDisabled}
            onClick={(event) => !isContinueDisabled && onContinueButton(event)}
            label={continueButtonLabel}
          />
        )}
      </div>
    </div>
  );
};
export default ActivitySplashAction;
