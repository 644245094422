export enum EBarScene {
  INSIDE_BAR = 'INSIDE_BAR',
  COCKTAIL = 'COCKTAIL',
  MIRROR = 'MIRROR',
  FREEZER = 'FREEZER',
  TOYS_MACHINE = 'TOYS_MACHINE',
  QUIZZ = 'QUIZZ',
  END = 'END',
}

export enum EBarSceneVape {
  INSIDE_BAR = 'INSIDE_BAR',
  COCKTAIL = 'COCKTAIL',
  MIRROR = 'MIRROR',
  FREEZER = 'FREEZER',
  TOYS_MACHINE = 'TOYS_MACHINE',
  VAPE_PUZZLE = 'VAPE_PUZZLE',
  QUIZZ = 'QUIZZ',
  END = 'END',
}

export enum EScene {
  START = 'START',
  USER_REGISTRATION = 'USER_REGISTRATION',
  INSIDE_BAR = 'INSIDE_BAR',
  COCKTAIL = 'COCKTAIL',
  MIRROR = 'MIRROR',
  TOYS_MACHINE = 'TOYS_MACHINE',
  FREEZER = 'FREEZER',
  FREEZER_COMPLETE = 'FREEZER_COMPLETE',
  VAPE_PUZZLE = 'VAPE_PUZZLE',
  QUIZZ = 'QUIZZ',
  END = 'END',
}

export enum EMusicScene {
  OUTSIDE = 'OUTSIDE',
  INSIDE = 'INSIDE',
  ACTIVITY = 'ACTIVITY',
  QUIZZ = 'QUIZZ',
}
