export enum EGender {
  MALE = 'hommes',
  FEMALE = 'femme',
  OTHERS = 'autres',
}

export enum EConsumption {
  SMOKER = 'fumeur',
  VAPER = 'vapoteur',
  OTHERS = 'autres',
  NOT_CHOSEN = '',
}
