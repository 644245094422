import React, { useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import Button from 'component/button/Button';
import SVGLoader from 'component/svg/SVG';
import {
  MachineToutousBoutonSVG,
  MachineToutousFlecheDroiteSVG,
  MachineToutousFlecheGaucheSVG,
  ToysAttrapeMP3,
  ToysDeplacementMP3,
} from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import './ToysMachineButton.scss';

const enum EButtonDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

interface IProps {
  onChange: (currentNumber: number) => void;
  onDrop: () => void;
}

const ToysMachineButton = ({ onChange, onDrop }: IProps): React.ReactElement => {
  const [currentNumber, setLockNumber] = useState<number>(3);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [isDropped, setIsDropped] = useState<boolean>(false);
  const [isSoundEnabled, setSoundEnabled] = useState<boolean>(false);
  const onChangeNumber = (newNumber: number): void => {
    setLockNumber(newNumber);
    onChange(newNumber);
  };

  const changeNumber = (action: EButtonDirection): void => {
    const isMusicEnabled = sceneInteraction.isMusicEnabled();
    setSoundEnabled(isMusicEnabled);
    setIsMoving(isMusicEnabled);
    window?.setTimeout((): void => {
      setIsMoving(false);
    }, 1500);
    if (action === EButtonDirection.LEFT) {
      if (currentNumber < 1) {
        onChangeNumber(1);
      } else {
        onChangeNumber(currentNumber - 1);
      }
    } else if (action === EButtonDirection.RIGHT) {
      if (currentNumber > 4) {
        onChangeNumber(4);
      } else {
        onChangeNumber(currentNumber + 1);
      }
    } else if (action === EButtonDirection.CENTER) {
      /**
       * If the "drop the claw" button is activate,
       * return the value to the parent component and also use a local state to disabled the button
       */
      if (currentNumber <= 4) {
        setIsDropped(true);
        onDrop();
      }
    }
  };

  return (
    <div className="toys-machine-button">
      {isMoving && isSoundEnabled && <H5AudioPlayer src={ToysDeplacementMP3} autoPlay />}
      {isDropped && isSoundEnabled && <H5AudioPlayer src={ToysAttrapeMP3} autoPlay />}
      <Button
        debounceTime={0}
        className="toys-machine-button__arrow toys-machine-button__arrow-left"
        aria-label="Bouger la grue à gauche"
        isDisabled={currentNumber <= 1 || isDropped}
        onClick={() => changeNumber(EButtonDirection.LEFT)}
      >
        <SVGLoader src={MachineToutousFlecheGaucheSVG} />
      </Button>
      <Button
        isDisabled={currentNumber >= 5 || isDropped}
        className="toys-machine-button__drop-btn"
        aria-label="Descendre la grue pour attraper le toutou"
        onClick={() => changeNumber(EButtonDirection.CENTER)}
      >
        <SVGLoader src={MachineToutousBoutonSVG} />
      </Button>
      <Button
        debounceTime={0}
        className="toys-machine-button__arrow toys-machine-button__arrow-right"
        aria-label="Bouger la grue à droite"
        isDisabled={currentNumber >= 4 || isDropped}
        onClick={() => changeNumber(EButtonDirection.RIGHT)}
      >
        <SVGLoader src={MachineToutousFlecheDroiteSVG} />
      </Button>
    </div>
  );
};
export default ToysMachineButton;
