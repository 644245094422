import userInteraction from './user-interaction';
import { EBarScene, EScene } from 'enum/scene';
import { IUserData } from 'interface/i-user';
import { EGTMEvent, EGTMProfile } from 'enum/gtm';
import { EConsumption } from 'enum/user';

declare global {
  interface Window {
    dataLayer: any;
  }
}
const dataLayer = window?.dataLayer;

/**
 * Create a service class to call GoogleTagManager
 * */
class GoogleTagManager {
  dataLayerPush(params: any): void {
    dataLayer.push(params);
  }

  getProfileGTM(): string {
    const userData: IUserData = userInteraction.getUserData();
    switch (userData.consumption) {
      case EConsumption.SMOKER:
        return EGTMProfile.SMOKER;
      case EConsumption.VAPER:
        return EGTMProfile.VAPER;
      case EConsumption.OTHERS:
        return EGTMProfile.OTHERS;
      default:
        return EGTMProfile.OTHERS;
    }
  }

  onStartButtonClick(): void {
    this.dataLayerPush({
      event: EGTMEvent.START,
    });
  }

  onEnterButtonClick(): void {
    this.dataLayerPush({
      event: EGTMEvent.ENTER,
      profile: this.getProfileGTM(),
    });
  }

  onSoundStart(): void {
    this.dataLayerPush({
      event: EGTMEvent.SOUND_ON,
    });
  }

  onSoundOff(): void {
    this.dataLayerPush({
      event: EGTMEvent.SOUND_OFF,
    });
  }

  onDefiButtonClick(): void {
    this.dataLayerPush({
      event: EGTMEvent.DEFI,
      profile: this.getProfileGTM(),
    });
  }

  onStandButtonClick(standName: EScene | EBarScene, alreadyDoneNumber: number): void {
    this.dataLayerPush({
      event: EGTMEvent.BEGIN,
      profile: this.getProfileGTM(),
      stand: standName.toLowerCase(),
      already_done: alreadyDoneNumber,
    });
  }

  onCancelButtonClick(standName: EScene | EBarScene, questionNumber: number | null): void {
    this.dataLayerPush({
      event: EGTMEvent.CANCEL,
      profile: this.getProfileGTM(),
      question_number: questionNumber || null,
      stand: standName.toLowerCase(),
    });
  }

  onContinueButtonClick(
    standName: EScene | EBarScene,
    ingredient1: string | null,
    ingredient2: string | null,
    ingredient3: string | null
  ): void {
    this.dataLayerPush({
      event: EGTMEvent.CONTINUE,
      profile: this.getProfileGTM(),
      ingredient1: ingredient1 || null,
      ingredient2: ingredient2 || null,
      ingredient3: ingredient3 || null,
      stand: standName.toLowerCase(),
    });
  }

  onQuitButtonClick(standName: EScene | EBarScene): void {
    this.dataLayerPush({
      event: EGTMEvent.QUIT,
      profile: this.getProfileGTM(),
      stand: standName.toLowerCase(),
    });
  }

  onRestartButtonClick(standName: EScene | EBarScene): void {
    this.dataLayerPush({
      event: EGTMEvent.TRY_AGAIN,
      profile: this.getProfileGTM(),
      stand: standName.toLowerCase(),
    });
  }

  onPhoneButtonClick(standName: EScene | EBarScene): void {
    this.dataLayerPush({
      event: EGTMEvent.PHONE,
      profile: this.getProfileGTM(),
      stand: standName.toLowerCase(),
    });
  }

  onFacebookShare(standName: EScene | EBarScene, resultQuiz: string): void {
    this.dataLayerPush({
      event: EGTMEvent.SHARE,
      profile: this.getProfileGTM(),
      resultQuiz,
      stand: standName.toLowerCase(),
    });
  }

  onDefiSubscribe(alreadyDoneNumber: number): void {
    this.dataLayerPush({
      event: EGTMEvent.SUBSCRIBE,
      profile: this.getProfileGTM(),
      already_done: alreadyDoneNumber,
    });
  }

  onPresentialProfileChoice(profile: EGTMProfile): void {
    this.dataLayerPush({
      event: EGTMEvent.PRESENTIAL,
      profile,
    });
  }
}

export default new GoogleTagManager();
