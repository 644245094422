import gsap from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

interface IScrollToOptions {
  element?: any;
  offsetY?: number;
  offsetX?: number;
  duration?: number;
  delay?: number;
  ease?: string;
}

class Utils {
  stringToBoolean(string: string): boolean {
    switch (string.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      case 'false':
      case 'no':
      case '0':
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }

  formatTimeToString(time: number): string {
    let minutes = Math.floor(time / 60).toString();
    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }
    let seconds = (time - parseInt(minutes, 10) * 60).toString();
    if (seconds.length === 1) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  scrollTo(
    options: IScrollToOptions = {
      element: window.document.querySelector('body'),
      duration: 0.5,
      offsetY: 0,
      offsetX: 0,
      delay: 0,
      ease: 'linear',
    }
  ) {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(options.element, {
      scrollTo: { y: options.offsetY, x: options.offsetX },
      duration: options.duration,
      delay: options.delay,
      ease: options.ease,
    });
  }
}

export default new Utils();
