import {
  addUserAge,
  addUserConsumptionType,
  addUserFirstName,
  addUserGender,
  addUserLastName,
  RESET_APP,
  RESET_APP_SMOKE,
  RESET_APP_VAPE,
} from 'action/user-action';
import { EConsumption, EGender } from 'enum/user';
import { IReduxState } from 'interface/i-redux-state';
import { IUserData } from 'interface/i-user';
import store from 'store/Store';

class UserInteraction {
  constructor() {
    this.setLastName = this.setLastName.bind(this);
    this.setFirstName = this.setFirstName.bind(this);
    this.setAge = this.setAge.bind(this);
    this.setGender = this.setGender.bind(this);
    this.resetApp = this.resetApp.bind(this);
  }

  setLastName(lastName: string) {
    store.dispatch(addUserLastName(lastName));
  }

  setFirstName(firstName: string) {
    store.dispatch(addUserFirstName(firstName));
  }

  setAge(age: number) {
    store.dispatch(addUserAge(age));
  }

  setConsumption(consumption: EConsumption) {
    store.dispatch(addUserConsumptionType(consumption));
  }

  setGender(gender: EGender) {
    store.dispatch(addUserGender(gender));
  }

  resetApp() {
    store.dispatch({ type: RESET_APP });
  }

  resetProfileSmoke() {
    store.dispatch({ type: RESET_APP_SMOKE });
  }

  resetProfileVape() {
    store.dispatch({ type: RESET_APP_VAPE });
  }

  getUserData(): IUserData {
    const state: IReduxState = store.getState();
    return {
      firstName: state.firstName,
      lastName: state.lastName,
      age: state.age,
      gender: state.gender,
      consumption: state.consumption,
    };
  }

  getUserConsumption(): EConsumption {
    const state: IReduxState = store.getState();
    return state.consumption;
  }
}

export default new UserInteraction();
