import { EConsumption, EGender } from 'enum/user';

export const ADD_OVERALL = 'ADD_OVERALL';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FIRSTNAME = 'ADD_USER_FIRSTNAME';
export const ADD_USER_LASTNAME = 'ADD_USER_LASTNAME';
export const ADD_USER_CONSUMPTION = 'ADD_USER_CONSUMPTION';
export const ADD_USER_AGE = 'ADD_USER_AGE';
export const ADD_USER_GENDER = 'ADD_USER_GENDER';
export const RESET_APP = 'RESET_APP';
export const RESET_APP_SMOKE = 'RESET_APP_SMOKE';
export const RESET_APP_VAPE = 'RESET_APP_VAPE';

export function addUserFirstName(firstName: string) {
  return {
    type: ADD_USER_FIRSTNAME,
    firstName,
  };
}
export function addUserLastName(lastName: string) {
  return {
    type: ADD_USER_LASTNAME,
    lastName,
  };
}
export function addUserAge(age: number) {
  return {
    type: ADD_USER_AGE,
    age,
  };
}
export function addUserGender(gender: EGender) {
  return {
    type: ADD_USER_GENDER,
    gender,
  };
}

export function addUserConsumptionType(consumption: EConsumption) {
  return {
    type: ADD_USER_CONSUMPTION,
    consumption,
  };
}
