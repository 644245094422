import React from 'react';
import googleTagManager from 'services/google-tag-manager';
import { EGTMProfile } from 'enum/gtm';
import Button from 'component/button/Button';
import sceneInteraction from 'services/scene-interaction';
import './SplashScreenPresential.scss';
import { EButtonColor, EButtonSize } from 'enum/button';
import userInteraction from 'services/user-interaction';
import { EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';

const SplashScreenPresential = (): React.ReactElement => {
  function setConsumptionAndMove(consumption: EConsumption) {
    userInteraction.setConsumption(consumption);
    sceneInteraction.goToScreen(EScene.MIRROR);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const type: EConsumption = urlParams.has('type') ? (urlParams.get('type') as EConsumption) : EConsumption.NOT_CHOSEN;

  const setConsumption = (consumption: EConsumption): void => {
    googleTagManager.onPresentialProfileChoice(
      consumption === EConsumption.SMOKER ? EGTMProfile.SMOKER : EGTMProfile.VAPER
    );
    setConsumptionAndMove(consumption);
  };

  return (
    <div className="splash-screen-presential">
      {!type && (
        <>
          <div className="mt-4 t-center">
            <Button isStyled size={EButtonSize.BIG} onClick={() => setConsumption(EConsumption.SMOKER)} label="Fumer" />
          </div>
          <div className="mt-4 t-center">
            <Button
              isStyled
              size={EButtonSize.BIG}
              onClick={() => setConsumption(EConsumption.VAPER)}
              label="Vapoter"
            />
          </div>
        </>
      )}
      {(type === EConsumption.SMOKER || type === EConsumption.VAPER) && (
        <Button
          isStyled
          size={EButtonSize.BIG}
          backgroundColor={EButtonColor.RED}
          onClick={() => setConsumption(type)}
          label="Viens voir les effets du Nico-Bar"
        />
      )}
    </div>
  );
};
export default SplashScreenPresential;
