import React from 'react';

interface ISVGLoaderProps {
  label?: string;
  className?: string;
  src: string;
}
/**
 * Component that will show an button.
 * @param label: string - Text to display inside the button
 * @param className: string - Add others classname to the component
 * @param src: string - The path to the SVG File
 * @returns React.ReactElement
 */

export const SVGLoader = ({ label = '', className = '', src }: ISVGLoaderProps): React.ReactElement => {
  const svgClassName = ['svg', className];
  return (
    <object type="image/svg+xml" className={svgClassName.join(' ')} data={src}>
      {label}
    </object>
  );
};

export default SVGLoader;
