import { useEffect, useRef } from 'react';

// Based on https://usehooks-typescript.com/react-hook/use-interval
function useInterval(callback: () => void, delay: number | null): any {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
