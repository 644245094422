import React from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';

import { PresentialBackgroundJPEG } from '../../services/exports-list';
import { EScene } from 'enum/scene';
import { IReduxState } from 'interface/i-redux-state';
// import ActionBar from 'component/action-bar/ActionBar';
import Header from 'presential/header/Header';
import ScreenStart from 'presential/screen-start/ScreenStart';
import ScreenMirror from 'presential/screen-mirror/ScreenMirror';

import 'component/scene-loader/SceneLoader.scss';

/**
 * Component that will load the good scene base on the Redux Store `currentScene` params.
 * @returns React.ReactElement
 */

export const SceneLoader = (): React.ReactElement => {
  const state: IReduxState = useSelector((newState: IReduxState) => newState);
  const sceneToLoad = (): React.ReactElement => {
    switch (state.currentScene) {
      case EScene.START:
        return <ScreenStart />;
      case EScene.MIRROR:
        return <ScreenMirror />;
      default:
        return <></>;
    }
  };

  return (
    <div className="scene-loader --presential" style={{ backgroundImage: `url(${PresentialBackgroundJPEG})` }}>
      <Header />
      <SwitchTransition>
        <CSSTransition
          key={state.currentScene}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          {sceneToLoad()}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default SceneLoader;
