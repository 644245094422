import {
  VapoteuseBatterieSVG,
  VapoteuseDripTipSVG,
  VapoteuseLiquidPODSVG,
  VapoteusePowerIndicatorSVG,
} from 'services/exports-list';
import { EVapePiece } from 'enum/puzzle';

class VapeInteraction {
  constructor() {
    this.getVapePieceByName = this.getVapePieceByName.bind(this);
  }

  /**
   * getVapePieceByName
   * Get the image of the vape part by is enum name
   * @params name: EVapePiece - The enum name of the vape puzzle piece
   * @return: string - the SVG export of the image
   */
  getVapePieceByName(name: EVapePiece): string {
    switch (name) {
      case EVapePiece.BATTERY:
        return VapoteuseBatterieSVG;
      case EVapePiece.POWER_INDICATOR:
        return VapoteusePowerIndicatorSVG;
      case EVapePiece.LIQUID_POD:
        return VapoteuseLiquidPODSVG;
      case EVapePiece.DRIP_TIP:
        return VapoteuseDripTipSVG;
      default:
        return '';
    }
  }
}

export default new VapeInteraction();
