import { EBarScene, EMusicScene, EScene, EBarSceneVape } from 'enum/scene';
import { EConsumption } from 'enum/user';

export const CHANGE_SCENE = 'CHANGE_SCENE';
export const COMPLETED_SCENE = 'COMPLETED_SCENE';
export const CHANGE_BAR_SCENE = 'CHANGE_BAR_SCENE';
export const CHANGE_MUSIC = 'CHANGE_MUSIC';
export const CHANGE_MUSIC_STATE = 'CHANGE_MUSIC_STATE';
export const COMPLETED_PROFILE = 'COMPLETED_PROFILE';

export function changeScene(newScene: EScene) {
  return {
    type: CHANGE_SCENE,
    currentScene: newScene,
  };
}

export function changeBarScene(newScene: EBarScene) {
  return {
    type: CHANGE_BAR_SCENE,
    currentBarScene: newScene,
  };
}

export function changeBarVapeScene(newScene: EBarSceneVape) {
  return {
    type: CHANGE_BAR_SCENE,
    currentBarScene: newScene,
  };
}

export function completeBarScene(scene: EScene) {
  return {
    type: COMPLETED_SCENE,
    completedBarScene: scene,
  };
}

export function changeMusic(newMusic: EMusicScene) {
  return {
    type: CHANGE_MUSIC,
    currentMusic: newMusic,
  };
}

export function changeMusicState(isMusicPlaying: boolean) {
  return {
    type: CHANGE_MUSIC_STATE,
    isMusicPlaying,
  };
}

export function completeProfile(profile: EConsumption) {
  return {
    type: COMPLETED_PROFILE,
    completedProfileApp: profile,
  };
}
