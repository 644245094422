import React, { useEffect } from 'react';
import MusicLoader from 'component/music-loader/MusicLoader';
import SceneLoader from 'component/scene-loader/SceneLoader';
import { SceneLoader as PresentialSceneLoader } from 'presential/scene-loader/SceneLoader';
import FontAwesomeService from 'services/font-awesome';
import './App.scss';

function App() {
  FontAwesomeService.initLibrairy();

  const resize = (): void => {
    const vh = window?.innerHeight * 0.01 || 10;
    document?.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const onInit = (): void => {
    if (document?.querySelector('body')) {
      const bodyElem: HTMLBodyElement | null = document?.querySelector('body');
      if (bodyElem) {
        const bodyHeight: number = bodyElem.clientHeight;
        const vh = (window?.innerHeight > bodyHeight ? bodyHeight : window?.innerHeight) * 0.01 || 10;
        document?.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    }
  };
  const addFaceBookDependencies = (): void => {
    window.fbAsyncInit = () => {
      window?.FB.init({
        appId: `${process.env.REACT_APP_FB_APP_ID}`,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      });
    };
  };

  // Add css var for the vh property on load and on resize.
  useEffect(() => {
    onInit();
    addFaceBookDependencies();
    window?.addEventListener('resize', resize);
  }, []);

  const loader: JSX.Element = process.env.REACT_APP_ENV === 'presential' ? <PresentialSceneLoader /> : <SceneLoader />;

  return (
    <div className="App">
      <MusicLoader />
      {loader}
    </div>
  );
}

export default App;
