import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider, TouchTransition, MouseTransition, Backends } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import reportWebVitals from './reportWebVitals';
import store from './store/Store';
import App from './App';

import './index.css';

export const HTML5toTouch: Backends = {
  backends: [
    {
      backend: HTML5Backend as any,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend as any,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

ReactDOM.render(
  <Provider store={store}>
    <DndProvider options={HTML5toTouch}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </DndProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
