import React, { useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import Button from 'component/button/Button';
import { ButtonClickMP3 } from 'services/exports-list';
import './LockerButton.scss';
import sceneInteraction from 'services/scene-interaction';

const enum ELockerDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

interface IProps {
  onChange: (currentNumber: number) => void;
}

const LockerButton = ({ onChange }: IProps): React.ReactElement => {
  const [currentNumber, setLockNumber] = useState(0);
  const [isClickSoundPlaying, toggleClickSound] = useState<boolean>(false);
  const isMusicEnabled = sceneInteraction.isMusicEnabled();
  const onChangeNumber = (newNumber: number): void => {
    setLockNumber(newNumber);
    onChange(newNumber);
  };

  const changeNumber = (direction: ELockerDirection): void => {
    toggleClickSound(true);
    if (direction === ELockerDirection.UP) {
      if (currentNumber === 9) {
        onChangeNumber(0);
      } else {
        onChangeNumber(currentNumber + 1);
      }
    } else if (direction === ELockerDirection.DOWN) {
      if (currentNumber === 0) {
        onChangeNumber(9);
      } else {
        onChangeNumber(currentNumber - 1);
      }
    }
    window?.setTimeout(() => {
      toggleClickSound(false);
    }, 225);
  };

  return (
    <div className="locker-button">
      {isClickSoundPlaying && isMusicEnabled && <H5AudioPlayer src={ButtonClickMP3} autoPlay />}
      <Button
        debounceTime={0}
        className="locker-button__arrow locker-button__arrow-up"
        aria-label="Monter le chiffre de 1"
        onClick={() => changeNumber(ELockerDirection.UP)}
        icon={['fas', 'chevron-up']}
      />
      <p className="locker-button__current-number" aria-label="Le chiffre est actuellement à: ">
        {currentNumber}
      </p>
      <Button
        debounceTime={0}
        className="locker-button__arrow locker-button__arrow-down"
        aria-label="Descendre le chiffre de 1"
        icon={['fas', 'chevron-down']}
        onClick={() => changeNumber(ELockerDirection.DOWN)}
      />
    </div>
  );
};
export default LockerButton;
