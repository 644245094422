import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faCaretDown,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faSignOut,
  faTimes,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/pro-solid-svg-icons';

class FontAwesomeService {
  constructor() {
    this.initLibrairy = this.initLibrairy.bind(this);
  }

  initLibrairy(): void {
    library.add(
      faCaretDown,
      faCaretRight,
      faVolumeMute,
      faVolumeUp,
      faSignOut,
      faArrowLeft,
      faChevronUp,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faTimes
    );
  }
}

export default new FontAwesomeService();
