import { uniq } from 'lodash';
import {
  CHANGE_BAR_SCENE,
  CHANGE_MUSIC,
  CHANGE_MUSIC_STATE,
  CHANGE_SCENE,
  COMPLETED_SCENE,
  COMPLETED_PROFILE,
} from 'action/scene-action';
import {
  ADD_USER_AGE,
  ADD_USER_CONSUMPTION,
  ADD_USER_FIRSTNAME,
  ADD_USER_GENDER,
  ADD_USER_LASTNAME,
  RESET_APP,
  RESET_APP_SMOKE,
  RESET_APP_VAPE,
} from 'action/user-action';
import { SELECT_INGREDIENT } from 'action/cocktail-action';
import {
  SET_FINAL_STEP_NUMBER_TOYS,
  SET_IS_CRANE_DOWN,
  SET_IS_DROPPED,
  SET_STEP_NUMBER_TOYS,
} from 'action/toys-action';
import { ECocktaiIngredientlList } from 'enum/cocktail';
import { EMusicScene, EScene } from 'enum/scene';
import { EConsumption, EGender } from 'enum/user';
import { IReduxState } from 'interface/i-redux-state';

const initialState: IReduxState = {
  firstName: '',
  lastName: '',
  consumption: EConsumption.NOT_CHOSEN,
  age: 0,
  gender: EGender.OTHERS,
  currentScene: EScene.START,
  currentMusic: EMusicScene.OUTSIDE,
  currentBarScene: EScene.INSIDE_BAR,
  completedBarScene: [],
  isMusicPlaying: false,
  completedProfileApp: [],
  selectedIngredient: Array(3),
  stepNumberToys: 3,
  finalStepNumberToys: 0,
  isDropped: false,
  isCraneDown: false,
};

const addCompleteScene = (completedScene: EScene[], currentScene: EScene) => {
  completedScene.push(currentScene);
  return uniq(completedScene);
};

const addCompleteProfile = (completedProfile: EConsumption[], currentProfile: EConsumption) => {
  completedProfile.push(currentProfile);
  return uniq(completedProfile);
};

const selectIngredient = (
  ingredient: ECocktaiIngredientlList,
  selectedIngredientList: ECocktaiIngredientlList[],
  index: number
) => {
  const newList: ECocktaiIngredientlList[] = selectedIngredientList;
  newList[index] = ingredient;
  return newList;
};

function rootReducer(state = initialState, action: any): any {
  switch (action.type) {
    case ADD_USER_FIRSTNAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case ADD_USER_LASTNAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case ADD_USER_AGE:
      return {
        ...state,
        age: action.age,
      };
    case ADD_USER_GENDER:
      return {
        ...state,
        gender: action.gender,
      };
    case ADD_USER_CONSUMPTION:
      return {
        ...state,
        consumption: action.consumption,
      };
    case CHANGE_SCENE:
      return {
        ...state,
        currentScene: action.currentScene,
      };
    case COMPLETED_SCENE:
      return {
        ...state,
        completedBarScene: addCompleteScene(state.completedBarScene, action.completedBarScene),
      };
    case CHANGE_BAR_SCENE:
      return {
        ...state,
        currentBarScene: action.currentBarScene,
      };
    case CHANGE_MUSIC:
      return {
        ...state,
        currentMusic: action.currentMusic,
      };
    case CHANGE_MUSIC_STATE:
      return {
        ...state,
        isMusicPlaying: action.isMusicPlaying,
      };
    case COMPLETED_PROFILE:
      return {
        ...state,
        completedProfileApp: addCompleteProfile(state.completedProfileApp, action.completedProfileApp),
      };
    case RESET_APP_SMOKE:
      return {
        ...state,
        consumption: EConsumption.SMOKER,
        age: 0,
        gender: EGender.OTHERS,
        currentScene: EScene.INSIDE_BAR,
        currentMusic: EMusicScene.INSIDE,
        currentBarScene: EScene.COCKTAIL,
        completedBarScene: [],
      };
    case RESET_APP_VAPE:
      return {
        ...state,
        consumption: EConsumption.VAPER,
        age: 0,
        gender: EGender.OTHERS,
        currentScene: EScene.INSIDE_BAR,
        currentMusic: EMusicScene.INSIDE,
        currentBarScene: EScene.COCKTAIL,
        completedBarScene: [],
      };
    case RESET_APP:
      initialState.completedBarScene = [];
      initialState.completedProfileApp = [];
      return initialState;
    case SELECT_INGREDIENT:
      return {
        ...state,
        selectedIngredient: selectIngredient(action.selectedIngredient, state.selectedIngredient, action.index),
      };
    case SET_STEP_NUMBER_TOYS:
      return {
        ...state,
        stepNumberToys: action.stepNumberToys,
      };
    case SET_FINAL_STEP_NUMBER_TOYS:
      return {
        ...state,
        finalStepNumberToys: action.finalStepNumberToys,
      };
    case SET_IS_DROPPED:
      return {
        ...state,
        isDropped: action.isDropped,
      };
    case SET_IS_CRANE_DOWN:
      return {
        ...state,
        isCraneDown: action.isCraneDown,
      };
    default:
      return state;
  }
}

export default rootReducer;
