export enum EGTMEvent {
  START = 'start-virtual-bar',
  ENTER = 'enter',
  SOUND_ON = 'sound-on',
  SOUND_OFF = 'sound-off',
  DEFI = 'defi',
  BEGIN = 'begin_stand',
  CANCEL = 'cancel_stand',
  CONTINUE = 'continue_stand',
  QUIT = 'quit_stand',
  TRY_AGAIN = 'try_again_stand',
  PHONE = 'phone_jarrete',
  SHARE = 'share_quiz',
  SUBSCRIBE = 'subscribe_challenge',
  PRESENTIAL = 'start-presential-bar',
}

export enum EGTMProfile {
  VAPER = 'vap',
  OTHERS = 'smoke-vap',
  SMOKER = 'smoke',
}
