import { createStore } from 'redux';
import rootReducer from '../reducers/state-reducer';

const store = createStore(
  rootReducer,
  undefined,
  // eslint-disable-next-line no-underscore-dangle
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

export default store;
