export enum EButtonSize {
  SMALL = '--small',
  MEDIUM = '--medium',
  BIG = '--big',
}

export enum EButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
}

export enum EButtonColor {
  YELLOW = '',
  OUTLINE = '--outline',
  RED = '--background-red',
}
