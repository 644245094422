import React from 'react';

import { SingleLogoPresentialSVG } from 'services/exports-list';
import SVGLoader from 'component/svg/SVG';

import './Header.scss';

/**
 * Component that will display the "exit" and "mute" button
 * @returns React.ReactElement
 */

export const Header = (): React.ReactElement => (
  <div className="header">
    <span className="header__logo">
      <SVGLoader src={SingleLogoPresentialSVG} />
    </span>
  </div>
);

export default Header;
